import { FieldErrorsImpl } from 'react-hook-form';

const getFieldError = (name: string, errors: FieldErrorsImpl<any>) => {
  let prop,
    // eslint-disable-next-line prefer-const
    props = name.split('.');

  // eslint-disable-next-line no-var
  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    const candidate = errors[prop];
    if (candidate !== undefined) {
      //@ts-ignore
      errors = candidate;
    } else {
      break;
    }
  }
  return errors[props[i]];
};

export default getFieldError;
