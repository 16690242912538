import * as React from 'react';
import { styled } from 'styles';
import color from 'styles/color';
import shadow from 'styles/shadow';
import typography from 'styles/typography';

import ErrorMessageInput from './error-message-input';
import HintMessageInput, { HintStatusEnum } from './hint-message-input';

export interface ITextareaFieldProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  hint?: string;
  errorMessage?: string;
}

const InputStyled = styled('textarea', {
  padding: 0,
  border: 'none',
  display: 'flex',
  width: '100%',
  outline: 'none',
  minHeight: 111,
  borderRadius: 6,
  resize: 'vertical',
});

const InputContainer = styled('div', {
  display: 'flex',
  minHeight: 111,
  alignItems: 'center',
  borderRadius: 6,
  borderWidth: 1,
  borderStyle: 'solid',
  padding: 16,
  backgroundColor: color.white,
  variants: {
    variant: {
      focus: {
        ...shadow.textInputFocus,
        borderColor: color.primary,
      },
      error: {
        ...shadow.textInputError,
        borderColor: color.error,
      },
      success: {
        ...shadow.textInputSuccess,
        borderColor: color.success,
      },
      disabled: {
        borderColor: color.neutral2,
        backgroundColor: color.neutral1,
      },
      default: {
        borderColor: color.neutral2,
      },
    },
  },
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Label = styled('label', {
  ...typography.body3,
  color: color.typeDisabled,
  marginBottom: 4,
  variants: {
    required: {
      true: {
        '&::after': {
          content: '*',
          marginLeft: 8,
          color: color.error,
          fontSize: 20,
        },
      },
    },
  },
});

const TextareaField = React.forwardRef<
  HTMLTextAreaElement,
  ITextareaFieldProps
>((props, ref) => {
  const { label, hint, errorMessage, ...rest } = props;
  const [state, setState] = React.useState<HintStatusEnum>(
    HintStatusEnum.default,
  );

  React.useEffect(() => {
    if (rest.disabled) {
      setState(HintStatusEnum.disabled);
    } else {
      setState(HintStatusEnum.default);
    }
  }, [rest.disabled]);
  React.useEffect(() => {
    if (errorMessage) {
      setState(HintStatusEnum.error);
    } else {
      setState(HintStatusEnum.default);
    }
  }, [errorMessage]);

  return (
    <Container>
      {label && <Label required={rest.required}>{label}</Label>}
      <InputContainer variant={state as any}>
        <InputStyled
          ref={ref}
          {...rest}
          onFocus={(e) => {
            rest.onFocus && rest.onFocus(e);
            setState(HintStatusEnum.focus);
          }}
          onBlur={(e) => {
            rest.onBlur && rest.onBlur(e);
            if (e.target.value.length > 0) {
              setState(HintStatusEnum.success);
            } else {
              setState(HintStatusEnum.default);
            }
          }}
        />
      </InputContainer>
      <ErrorMessageInput message={errorMessage} />
      <HintMessageInput status={state} message={hint} />
    </Container>
  );
});

TextareaField.displayName = 'TextareaField';

export default TextareaField;
