import { useGetUploadFileParam } from 'api-hooks/upload/upload.mutation';
import { ResizeImage } from 'common/helpers/image';
import Text from 'components/commons/text';
import { camelizeKeys, decamelize } from 'humps';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { X } from 'react-feather';
import { Oval } from 'react-loader-spinner';
import color from 'styles/color';

import { ACCEPTED_FILE } from './main-file-picker';
import {
  PreviewContainer,
  ImagePreviewContainer,
  ImagePreview,
  RemoveImage,
  EditComponent,
  LoadingContainer,
  FilePreview,
} from './styles';

export interface PreviewOptions {
  id?: string;
  name?: string;
  disabled?: boolean;
  preview: string;
  onPicked: (filename: string, fullUrl: string) => void;
  onDelete?: () => void;
  accept?: string;
  isError?: boolean;
  resizeImage?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
}

export function PreviewContent({ url }) {
  const splits = url?.split('?')[0].split('.');
  const extension = splits[splits?.length - 1]?.toLowerCase();

  const currentUrl = url.startsWith('blob:')
    ? url.replace(`.${extension}`, '')
    : url;

  if (['jpeg', 'jpg', 'png'].includes(extension)) {
    return <ImagePreview src={currentUrl} alt={extension} />;
  }

  if (['pdf', 'xlsx', 'docx', 'doc', 'xls'].includes(extension)) {
    const Icon = (extension) => {
      switch (extension.extension) {
        case 'xls':
        case 'xlsx':
          return <FilePreview src="/assets/file-excel.png" alt={extension} />;
        case 'doc':
        case 'docx':
          return <FilePreview src="/assets/file-word.png" alt={extension} />;
        case 'pdf':
          return <FilePreview src="/assets/file-pdf.png" alt={extension} />;
        default:
          return <FilePreview src="/assets/global-file.png" alt={extension} />;
      }
    };
    return (
      <PreviewContainer>
        <Icon extension={extension} />
      </PreviewContainer>
    );
  }

  return <FilePreview src="/assets/global-file.png" alt={extension} />;
}

export default function PreviewPicker(props: PreviewOptions) {
  const {
    onPicked,
    onDelete,
    preview,
    disabled,
    resizeImage,
    resizeWidth,
    resizeHeight,
  } = props;
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = React.useState(false);
  const { mutateAsync: uploadFileParam } = useGetUploadFileParam();

  const ResizeImageFunc = React.useCallback(
    async (file, onImageResized, type) => {
      await ResizeImage({
        imageToResize: file,
        width: resizeWidth!,
        height: resizeHeight!,
        onImageResized,
        type,
      });
    },
    [resizeHeight, resizeWidth],
  );

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const fileToUpload = acceptedFiles[0];
        setIsUploading(true);

        let result: any = await uploadFileParam({
          contentType: fileToUpload.type,
        });
        result = await camelizeKeys(result);
        const type = result.inputs.key.split('.')[1];

        if (!result) {
          throw new Error('Data is undefined');
        }
        const inputs = result.inputs;
        const formData = new FormData();

        const fileBlob = Object.assign(fileToUpload, {
          preview: URL.createObjectURL(fileToUpload),
        });

        if (resizeImage) {
          await ResizeImageFunc(
            fileBlob,
            async (uri: any) => {
              Object.keys(inputs).forEach((key) => {
                formData.append(
                  decamelize(key, { separator: '-' }),
                  inputs[key],
                );
              });
              formData.append('Content-Type', fileToUpload.type);
              formData.append('file', uri);

              const { action, method } = result.attributes;
              const uploadResponse = await fetch(action, {
                method,
                body: formData,
              });
              const uploadResponseBody = await uploadResponse.text();
              if (!uploadResponse.ok) {
                throw new Error(uploadResponseBody);
              }

              const newFileBlob = Object.assign(uri, {
                preview: URL.createObjectURL(uri),
              });

              onPicked(result.fileName, newFileBlob.preview + '.' + type);
              setIsUploading(false);
            },
            fileToUpload.type,
          );
        } else {
          Object.keys(inputs).forEach((key) => {
            formData.append(decamelize(key, { separator: '-' }), inputs[key]);
          });
          formData.append('Content-Type', fileToUpload.type);
          formData.append('file', fileToUpload);

          const { action, method } = result.attributes;
          const uploadResponse = await fetch(action, {
            method,
            body: formData,
          });
          const uploadResponseBody = await uploadResponse.text();
          if (!uploadResponse.ok) {
            throw new Error(uploadResponseBody);
          }

          const newFileBlob = Object.assign(fileToUpload, {
            preview: URL.createObjectURL(fileToUpload),
          });

          onPicked(result.fileName, newFileBlob.preview + '.' + type);
          setIsUploading(false);
        }
      } catch (e) {
        setIsUploading(false);
      } finally {
      }
    },
    [ResizeImageFunc, onPicked, resizeImage, uploadFileParam],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const { onClick: openFilePicker, ...rootProps } = getRootProps({
    refKey: 'innerRef',
  });

  const onRootClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!isUploading) {
        const splits = preview?.split('?')[0].split('.');
        const currentUrl = preview.startsWith('blob:') ? splits[0] : preview;
        window.open(currentUrl);
      }
    },
    [isUploading, preview],
  );

  return (
    <PreviewContainer onClick={onRootClick} {...rootProps}>
      <ImagePreviewContainer>
        {isUploading ? (
          <LoadingContainer>
            <Oval color={color.primary} secondaryColor={color.primary5} />
          </LoadingContainer>
        ) : (
          <>
            <PreviewContent url={preview} />
            {!disabled && (
              <>
                <RemoveImage
                  onClick={(e) => {
                    !!onDelete && onDelete();
                    e.stopPropagation();
                  }}
                >
                  <X />
                </RemoveImage>
                <EditComponent
                  onClick={(e) => {
                    openFilePicker && openFilePicker(e);
                    e.stopPropagation();
                  }}
                >
                  <Text variant="body2">{t('common:edit')}</Text>
                </EditComponent>
              </>
            )}
            <input
              {...getInputProps()}
              id={props.id}
              name={props.name}
              accept={ACCEPTED_FILE}
              disabled={disabled}
            />
          </>
        )}
      </ImagePreviewContainer>
    </PreviewContainer>
  );
}
