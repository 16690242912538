import { useGetUploadFileParam } from 'api-hooks/upload/upload.mutation';
import { ResizeImage } from 'common/helpers/image';
import Text from 'components/commons/text';
import { camelizeKeys, decamelize } from 'humps';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Plus } from 'react-feather';
import { Oval } from 'react-loader-spinner';
import color from 'styles/color';

import {
  BorderContainer,
  MainDropzoneContainer,
  TextContainer,
} from './styles';

export interface FilePickerOptions {
  id?: string;
  name?: string;
  disabled?: boolean;
  onPicked: (filename: string, fullUrl: string) => void;
  accept?: string;
  isError?: boolean;
  resizeImage?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
}

export const ACCEPTED_FILE =
  'application/pdf,image/*,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessing';

export default function MainFilePicker(props: FilePickerOptions) {
  const {
    onPicked,
    disabled,
    isError,
    resizeImage,
    resizeWidth,
    resizeHeight,
  } = props;
  const [isUploading, setIsUploading] = React.useState(false);
  const { mutateAsync: uploadFileParam } = useGetUploadFileParam();
  const { t } = useTranslation();

  const ResizeImageFunc = React.useCallback(
    async (file, onImageResized, type) => {
      await ResizeImage({
        imageToResize: file,
        width: resizeWidth!,
        height: resizeHeight!,
        onImageResized,
        type,
      });
    },
    [resizeHeight, resizeWidth],
  );

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const fileToUpload = acceptedFiles[0];
        setIsUploading(true);

        let result: any = await uploadFileParam({
          contentType: fileToUpload.type,
        });
        result = await camelizeKeys(result);
        if (!result) {
          throw new Error('Data is undefined');
        }

        const splits = fileToUpload.name.split('.');
        const fileExtension = splits[splits?.length - 1].toLowerCase();
        const blobUrl = URL.createObjectURL(fileToUpload).concat(
          `.${fileExtension}`,
        );

        const inputs = result.inputs;
        const formData = new FormData();

        const fileBlob = Object.assign(fileToUpload, {
          preview: blobUrl,
        });

        if (resizeImage) {
          await ResizeImageFunc(
            fileBlob,
            async (uri: any) => {
              Object.keys(inputs).forEach((key) => {
                formData.append(
                  decamelize(key, { separator: '-' }),
                  inputs[key],
                );
              });
              formData.append('Content-Type', fileToUpload.type);
              formData.append('file', uri);

              const { action, method } = result.attributes;
              const uploadResponse = await fetch(action, {
                method,
                body: formData,
              });
              const uploadResponseBody = await uploadResponse.text();
              if (!uploadResponse.ok) {
                throw new Error(uploadResponseBody);
              }

              onPicked(result.fileName, blobUrl);
              setIsUploading(false);
            },
            fileToUpload.type,
          );
        } else {
          Object.keys(inputs).forEach((key) => {
            formData.append(decamelize(key, { separator: '-' }), inputs[key]);
          });
          formData.append('Content-Type', fileToUpload.type);
          formData.append('file', fileToUpload);

          const { action, method } = result.attributes;
          const uploadResponse = await fetch(action, {
            method,
            body: formData,
          });
          const uploadResponseBody = await uploadResponse.text();
          if (!uploadResponse.ok) {
            throw new Error(uploadResponseBody);
          }

          onPicked(result.fileName, blobUrl);
          setIsUploading(false);
        }
      } catch (e) {
        setIsUploading(false);
      } finally {
      }
    },
    [ResizeImageFunc, onPicked, resizeImage, uploadFileParam],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const rootProps = getRootProps({
    refKey: 'innerRef',
  });

  if (disabled) {
    return null;
  }

  return (
    <MainDropzoneContainer {...rootProps}>
      <BorderContainer error={isError}>
        {isUploading ? (
          <>
            <Oval color={color.primary} secondaryColor={color.primary5} />
          </>
        ) : (
          <>
            <TextContainer>
              <Plus />
              <Text variant="body2">{t('common:browse_or_drop_file')}</Text>
            </TextContainer>
            <input
              {...getInputProps()}
              id={props.id}
              name={props.name}
              accept={ACCEPTED_FILE}
              disabled={disabled}
            />
          </>
        )}
      </BorderContainer>
    </MainDropzoneContainer>
  );
}
